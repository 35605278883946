import React from 'react';
import Helmet from "react-helmet";
import Layout from '../../components/layout';

import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import SvgIcon from '@material-ui/core/SvgIcon';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import './review.scss';

const TAB_MANAGER_EXTENSION_STORE_URL = 'https://chrome.google.com/webstore/detail/tabmanagerio-session-tab/hjicnemefbakbanoigbaonlpgflhggdf?hl=en';
const CLUSTER_EXTENSION_STORE_URL = 'https://chrome.google.com/webstore/detail/cluster-window-tab-manage/aadahadfdmiibmdhfmpbeeebejmjnkef?hl=en';

function createData(name, tabmanager, cluster) {
  return {
    name,
    tabmanager,
    cluster,
  };
}

const rows = [
  createData('Window Manager', true, true),
  createData('Tab Manager', true, true),
  createData('Search Tabs', true, true),
  createData('Multiple Columns', true, true),
  createData('Save Windows & Tabs', true, true),
  createData('Import & Export', true, true),
  createData('Suspend Tabs', true, true),
  createData('Sort Tabs', true, true),
  createData('Keyboard Navigation', true, true),
  createData('Close Duplicate Tabs', true, true),
  createData('Name Windows', true, false),
  createData('Drag & Drop Tabs', true, false),
  createData('Drag & Drop Windows', true, false),
  createData('Cloud Backup & Sync', true, false),
  createData('Session Manager', true, false),
];

function CheckIcon() {
  return (
    <SvgIcon style={{ color: 'green' }}><CheckCircleIcon /></SvgIcon>
  );
}

function UncheckIcon() {
  return (
    <SvgIcon style={{ color: 'red' }}><CancelIcon /></SvgIcon>
  );
}

function FeatureTable() {
  return (
    <TableContainer component={Paper}>
      <Table aria-label='Tabmanager.io vs cluster feature comparison'>
        <TableHead>
          <TableRow>
            <TableCell><h3>Feature</h3></TableCell>
            <TableCell align='center'>
              <a href={TAB_MANAGER_EXTENSION_STORE_URL}>
                <h3>TabManager.io</h3>
              </a>
            </TableCell>
            <TableCell align='center'>
              <a href={CLUSTER_EXTENSION_STORE_URL}>
                <h3>Cluster</h3>
              </a>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component='th' scope='row'>
                <h4>{row.name}</h4>
              </TableCell>
              <TableCell align='center'>
                {row.tabmanager
                  ? <CheckIcon />
                  : <UncheckIcon />
                }
              </TableCell>
              <TableCell align='center'>
                {row.cluster
                  ? <CheckIcon />
                  : <UncheckIcon />
                }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default function BlogPage() {
  return (
    <>
      <Helmet>
        <script type="application/ld+json">{`
          {
            "@context":"http://schema.org",
            "@type":"WebPage",
            "description":"Looking for the best Cluster Tab Manager alternative? Here we review the best alternative to Cluster",
            "headline":"Best Cluster Tab Manager alternative | TabManager.io",
            "image":{
              "@type":"ImageObject",
              "url":"https://tabmanager.io/images/mq-promo.png"
            },
            "publisher":{
              "@type":"Organization",
              "legalName":"Tabmanager.io.",
              "logo":{
                "@type":"ImageObject",
                "height":32,
                "url":"https://tabmanager.io/logo.svg",
                "width":32
              }
            },
            "url":"https://tabmanager.io/reviews/best-cluster-tab-manager-alternative"
          }
        `}</script>
      </Helmet>
      <Layout
        title={`Best Cluster Tab Manager alternative ${new Date().getFullYear()} - TabManager.io`}
        description='Best Cluster Tab Manager extension alternative | Review'
        keywords={[
          'tab manager review',
          'cluster tab manager',
          'tabmanager.io tab manager',
          'best tab manager chrome',
          'window chrome extension',
          'best tab manager for chrome',
          'session manager',
        ]}
      >
        <div className='root' style={{ paddingTop: '3rem' }}>
          <Container maxWidth='md'>
            <div>
              <h1>{`Best Cluster Tab Manager alternative in ${new Date().getFullYear()}`}</h1>
            </div>
            <div style={{ marginTop: '2rem' }}>
              <img src='/images/mq-promo.png' alt='chrome tab manager' width='100%'/>
            </div>
            <div style={{ marginTop: '2rem' }}>
              <p>
                <b>TabManager.io</b> is a Window and Tab Manager Chrome extension with all the
                features to keep you productive when working on multiple projects and need to
                organise your windows and tabs.
              </p>

              <p>
                If you are already familiar with using the <b>Cluster - Window & Tab Manager</b> extension to manage your
                tabs and windows then switching over to <b>TabManager.io</b> is easy.
              </p>

              <p>
                <b>TabManager.io</b> has feature parity with <b>Cluster</b> so that you can still continue using the features
                that you depend on in your workflow. We have also added all the missing features that a good tab manager
                should have, such as <b>naming windows</b>, <b>session management</b> and <b>backup & sync across devices</b>.
              </p>

              <div style={{ marginTop: '2rem' }}>
                <img src='/images/screen-1.png' alt='tabmanager.io extension screenshot' width='100%' />
              </div>

              <p>
                If you have saved windows that you want to keep and transfer over, you can export them and import
                them into <b>TabManager.io</b> using the built-in importer.
              </p>

              <div style={{ marginTop: '3rem', marginBottom: '2rem', display: 'flex', justifyContent: 'center' }}>
                <Button
                  variant='contained'
                  size='large'
                  color='primary'
                  href={TAB_MANAGER_EXTENSION_STORE_URL}
                  target='_blank'
                >
                  Get Tabmanager.io
                </Button>
              </div>

              <p>
                Below is a list comparison of all the current features that <b>Tabmanager.io</b> and <b>Cluster</b> supports.
              </p>
            </div>
            <div style={{ marginTop: 40 }}>
              <FeatureTable />
            </div>

            <div style={{ marginTop: '3rem', marginBottom: '2rem', display: 'flex', justifyContent: 'center' }}>
              <Button
                variant='contained'
                size='large'
                color='primary'
                href={TAB_MANAGER_EXTENSION_STORE_URL}
                target='_blank'
              >
                Get Tabmanager.io
              </Button>
            </div>

          </Container>
        </div>
      </Layout>
    </>
  );
}
